import * as React from "react"

const Mission = () => (
  <div className="wrapper">
    <h3 className="heading" id="mission">
      Mission
    </h3>
    <div>
      <h4>
        <b>Our Mission: </b>is to make renewable energy cheaper, electricity
        markets more efficient, and grids more adaptable.
      </h4>
    </div>
  </div>
)

export default Mission
