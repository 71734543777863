import * as React from "react"
import SiteLayout from "../containers/sitelayout"
import Mission from "../containers/mission"
import Team from "../containers/team"
import Contact from "../containers/contact"
import Vision from "../containers/vision"
import Seo from "../components/seo"

const About = () => (
  <SiteLayout>
    <Seo title="About us" />
    <Mission />
    {/* <Team /> */}
    {/* <Vision /> */}
    {/* <Contact /> */}
  </SiteLayout>
)

export default About
